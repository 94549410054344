.header-container {
    background: #232f3e;
    padding: 0px 10px;
    height: 44px;
    line-height: 44px;
}

.header-title {
    font-weight: 600;
    color: #ffffff;
    font-size: 20px;
    margin-left: 8px;
}

.main-container {
    overflow: hidden scroll;
    background: #dadada;
    padding: 10px;
}

.image-container {
    position: relative;
}

.image-title {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    color: #fff;
    opacity: 0.8;
    border: none;
    border-radius: 0;
    /* word-wrap: break-word; */
    padding: 2px;
}

.image-ribbon {
    position: absolute;
    top: 0;
    right: 0;
    background: #eb5f07;
    color: #fff;
    padding: 2px;
}

.tree-item {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
}

.tree-item:hover {
    color: #eb5f07;
}

.select-summary {
    background-color: #232f3e;
    color: #ffffff;
    position: absolute;
    bottom: 10px;
    right: 310px;
    width: 500px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
    z-index: 10;
}

.right-sider {
    overflow: hidden scroll;
    padding: 10px;
    text-align: justify;
}