.login-container {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: arial;
    font-weight: 500;

}

.first-login-form {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-form {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 60px;
}

.login-noti {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    color: red;
}